import {
  CreateTerminalInput,
  MutationCreateTerminalArgs,
  Terminal,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { TERMINAL_FIELDS } from "@/graphql/terminal/terminals";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { trim } from "@/graphql/utils/utils";

type CreateTerminalData = {
  createTerminal: Terminal;
};

const CREATE_TERMINAL = gql`
    mutation CreateTerminal($input: CreateTerminalInput!){
        createTerminal(input:$input){
            ${TERMINAL_FIELDS}
        }
    }
`;

export const useCreateTerminal = () => {
  const toast = useToast();
  const { t } = useI18n();
  const dialog = ref(false);
  const input = reactive<CreateTerminalInput>({
    active: 1,
    codeBar: 0,
    printBarcode: 0,
    activityId: activeActivity.value.id,
    printStockPos: 1,
    allowDiscountLines: 0,
    categories: [],
    description: "",
    name: "",
    printerSettings: [],
    printerMargins: [],
    userIds: [],
  });

  const {
    loading: createLoading,
    mutate,
    onDone,
  } = useMutation<CreateTerminalData, MutationCreateTerminalArgs>(
    CREATE_TERMINAL,
    {
      update: (cache, { data }) => {
        if (data) {
          cache.modify({
            fields: {
              terminals(existingRef: any[], { toReference }) {
                return [...existingRef, toReference(data.createTerminal)];
              },
            },
          });
        }
      },
    }
  );

  onDone(({ data }) => {
    const success = !!data?.createTerminal;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("terminal.newTerminal"),
      detail: t(`terminal.create.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    dialog.value = !success;
  });
  function createTerminal() {
    trim(input);
    void mutate({ input });
  }
  return {
    createLoading,
    createTerminal,
    input,
    status: [
      { value: 1, label: t("terminal.enabled") },
      { value: 0, label: t("terminal.disabled") },
    ],
    dialog,
  };
};
