
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useCreateTerminal } from "@/graphql/terminal/create-terminal";

export default defineComponent({
  name: "TerminalForm",
  props: ["number"],
  setup() {
    return {
      ...useCreateTerminal(),
    };
  },
});
